<template>
  <v-dialog persistent v-model="dialogFlag" max-width="1200px">
    <v-card height="100%">

      <v-card-title>
        <span class="text-h5">User Orders and Trades</span>
      </v-card-title>

      <v-card-text>
        <v-container>

          <v-row>
            <v-col cols="12">
              <v-treeview @input="onSelect" dense :load-children="loadTrades" selectable item-disabled="locked"
                :items="userOrders">

                <template #label="{ item }">
                  <v-row v-if="item.rowType === 'Order'" align="center">
                    <v-col cols="1">
                      <div :style="`color: ${item.rowType === 'Order' ? 'white' : 'blue'}`">{{ item.rowType }}</div>
                    </v-col>
                    <v-col cols="2">
                      {{ item.symbol }}
                    </v-col>
                    <v-col cols="1">
                      <div :style="`color: ${item.side === 'BUY' ? 'green' : 'red'}`">{{ item.side }}</div>
                    </v-col>
                    <v-col cols="2">
                      {{ item.status }}
                    </v-col>
                    <v-col cols="1">
                      {{ item.price }}
                    </v-col>
                    <v-col cols="1">
                      {{ item.executedQty }} / {{ item.origQty }}
                    </v-col>
                    <v-col cols="1">
                      {{ item.traderId }}
                    </v-col>
                    <v-col cols="2">
                      {{ new Date(item.time).toLocaleString() }}
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="1">
                      <div :style="`color: ${item.rowType === 'Order' ? 'yellow' : 'cyan'}`">{{ item.rowType }}</div>
                    </v-col>
                    <v-col cols="2">
                      {{ item.symbol }}
                    </v-col>
                    <v-col cols="1">
                      <div :style="`color: ${item.side === 'BUY' ? 'green' : 'red'}`">{{ item.side }}</div>
                    </v-col>
                    <v-col cols="1">
                      {{ item.price }}
                    </v-col>
                    <v-col cols="2">
                      {{ item.qty }}
                    </v-col>
                    <v-col cols="2">
                      {{ item.commission.toFixed(4) }} {{ item.commissionAsset }}
                    </v-col>
                    <v-col cols="2">
                      {{ new Date(item.time).toLocaleString() }}
                    </v-col>
                  </v-row>
                </template>
              </v-treeview>
            </v-col>
          </v-row>


        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-text-field v-model="positionAmtInput" label="Position Amt"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="entryPriceInput" label="Entry Price"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="newEntryPrice" label="New Entry Price"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="newPositionAmt" label="New Position Amt"></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox v-model="useBase" color="secondary" />
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="calc"> Calc </v-btn>
        <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>

import { Strategies } from '@/constants';
import { tradeMixin } from "@/mixins";
import SelectorSingle from '@/views/dashboard/component/SelectorSingle';
import SelectorMulti from '@/views/dashboard/component/SelectorMulti';

import { get_user_trades, } from '@/api';

export default {
  name: "UserTradesDlg",

  mixins: [tradeMixin],

  components: {
    SelectorSingle,
    SelectorMulti,
  },

  props: {
    positionAmt: {
      type: Number,
    },
    entryPrice: {
      type: Number,
    },

    gatewayName: {
      type: String,
    },
    userOrders: {
      type: Array,
      default: () => [],
    },
    dialogFlag: {
      type: Boolean,
      default: false,
    },
    cancelFunc: {
      type: Function,
    },
  },
  watch: {

    positionAmt(val) {
      this.positionAmtInput = val
    },

    entryPrice(val) {
      this.entryPriceInput = val
    },

  },

  mounted: async function () {
  },

  methods: {
    close() {
      this.newEntryPrice = 0;
      this.newPositionAmt = 0;
      this.cancelFunc()
    },
    onSelect(items) {
      this.tradeIDs = items
    },
    async calc() {

      let marketValue = parseFloat(this.entryPriceInput) * parseFloat(this.positionAmtInput);
      let qty = parseFloat(this.positionAmtInput);

      this.tradeIDs.forEach(async (item) => {

        if (this.tradeMap.hasOwnProperty(item)) {

          const trade = this.tradeMap[item]
          const currValue = trade.price * trade.qty;
          const currQty = trade.qty;

          const flag = trade.side === 'BUY' ? 1 : -1;
          marketValue += flag * currValue;
          qty += flag * currQty;
        }
      })

      this.newEntryPrice = qty === 0 ? 0 : (marketValue / qty);
      this.newPositionAmt = qty;
    },

    async loadTrades(item) {

      item.children = []

      let id = 0

      const trades = await get_user_trades({ gatewayName: this.gatewayName, symbol: item.symbol, orderId: item.orderId, })
      if (trades.user_trades !== undefined) {
        const children = trades.user_trades.map((trade) => {
          const name = `trade: [${trade.symbol}][${trade.side}] ---p:${trade.price} ---q:${trade.qty} [${trade.tradeId}]`
          const showId = `${item.orderId}_${++id}`
          this.tradeMap[showId] = trade
          return { id: showId, rowType: 'Trade', name, ...trade }
        })
        item.children = children
      }
    },
    onStrategyChange(ev) {
      this.strategyName = ev;
    },
    onGatewaysChange(ev) {
      this.gateways = ev;
    }
  },

  computed: {
    internalDialogFlag: {
      get: function () {
        return this.dialogFlag;
      },
      set: function (newVal) { },
    },
  },

  data: function () {
    return {
      tradeMap: {},
      tradeIDs: [],
      selected: [],
      useBase: true,
      newEntryPrice: 0,
      newPositionAmt: 0,
      positionAmtInput: 0,
      entryPriceInput: 0,
      strategies: Strategies,
    };
  }
};
</script>
