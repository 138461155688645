<template>
  <v-autocomplete v-model="value" :items="items" :label="title" v-on:change="onChange" single solo></v-autocomplete>
</template>

<script>
export default {
  name: "SelectorSingle",

  props: {
    title: String,
    items: Array,
    onChange: Function,
    initValue: {
      type: String,
      default: '',
    },
  },

  watch: {
    initValue(val) {
      this.value = val;
    }
  },

  mounted: function () {
    this.value = this.initValue;
  },

  data() {
    return {
      value: '',
    };
  },

  methods: {
    reset() {
      this.value = this.initValue;
    },
  },
};
</script>

<style lang="scss">
</style>
