<template>
  <v-autocomplete
    v-model="value"
    :items="items === undefined ? [] : items"
    deletable-chips
    small-chips
    :label="title"
    v-on:change="onChange"
    multiple
    solo
  ></v-autocomplete>
</template>

<script>
export default {
  name: "SelectorMulti",

  props: {
    title: String,
    items: Array,
    onChange: Function,
    initValue: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
      initValue(val) {
          this.value = val;
      },
  },

  mounted: function () {
  },

  data() {
    return {
      value: [],
    };
  },

  methods: {
    reset() {
      this.value = [];
    },
  },
};
</script>

<style lang="scss"></style>
