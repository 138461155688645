<template>
  <div>
    <v-data-table
      dense
      width="100%"
      :headers="orders_header"
      :items="orders"
      class="elevation-1"
      sort-by="updateTime"
      :sort-desc="true"
    >
      <template v-if="isAlive" v-slot:top>
        <v-row justify="end"
          ><v-col class="ma-5" cols="2"
            ><v-btn :disabled="orders == undefined || orders.length === 0" @click="tryToCancelAll">Cancel All</v-btn></v-col
          ></v-row
        >
      </template>

      <template v-slot:item.symbol="{ item }">
        <strong><b>{{item.symbol}}</b></strong>
      </template>

      <template v-slot:item.price="{ item }">
        <strong><b>{{item.price === 0 ? '-' : item.price }}</b></strong>
      </template>

      <template v-slot:item.updateTime="{ item }">
        {{ new Date(item.updateTime).toLocaleString() }}
      </template>

      <template v-slot:item.side="{ item }">
        <div :style="`color: ${item.side === 'BUY' ? 'green': 'red'}`">{{ item.side }}</div>
      </template>

      <template v-slot:item.positionSide="{ item }">
        <div :style="`color: ${item.side === 'BUY' ? 'green': 'red'}`">{{ item.positionSide }}</div>
      </template>

      <template v-slot:item.duration="{ item }">
       {{ item.duration }} ms
      </template>

      <template v-if="isAlive" v-slot:item.actions="{ item }">
        <v-icon small @click="tryToCancel(item)">mdi-delete</v-icon>
      </template>

    </v-data-table>

    <confirm-dialog
      v-if="isAlive"
      max_width="400px"
      text="do u wanna cancel order?"
      :dialogFlag="dlgCancel"
      :cancelFunc="closeCancelDlg"
      :confirmFunc="cancel"
    ></confirm-dialog>

    <confirm-dialog
      v-if="isAlive"
      max_width="400px"
      text="do u wanna cancel all orders?"
      :dialogFlag="dlgCancelAll"
      :cancelFunc="closeCancelAllDlg"
      :confirmFunc="cancelAll"
    ></confirm-dialog>
  </div>
</template>

<script>
import { tradeMixin } from "@/mixins";

import ConfirmDialog from "@/components/base/ConfirmDialog";

export default {
  name: "OrderTable",

  components: {
    ConfirmDialog,
  },

  mixins: [tradeMixin],

  props: {
    gatewayName: {
      type: String,
      default: undefined,
    },
    accountType: {
      type: String,
      default: 'BINANCE_USDT_FUTURE'
    },
    orders: Array,
    isAlive: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    tryToCancel(item) {
      this.item = item;
      this.dlgCancel = true;
    },
    closeCancelDlg() {
      this.dlgCancel = false;
    },
    cancel() {
      this.closeCancelDlg();
      console.log(this.item)
      this.cancelOrder(this.item);
    },

    tryToCancelAll() {
      this.dlgCancelAll = true;
    },
    closeCancelAllDlg() {
      this.dlgCancelAll = false;
    },
    cancelAll() {
      this.closeCancelAllDlg();
      this.orders.forEach((item) => {
        this.cancelOrder(item);
      })
    },
  },

  mounted: function () {
    if (this.accountType === 'SPOT') {
      this.orders_header.splice(3, 1)
      this.orders_header.splice(5, 1)
    }
    if (this.isAlive) {
      this.orders_header.push({
        text: "Actions",
        value: "actions",
        sortable: false,
      });
    }
  },

  data() {
    return {
      item: undefined,
      dlgCancel: false,
      dlgCancelAll: false,
      orders_header: [
        { text: "Symbol", value: "symbol" },
        { text: "Type", value: "type" },
        { text: "Side", value: "side" },
        { text: "PositionSide", value: "positionSide" },
        { text: "Status", value: "status" },
        { text: "Price", value: "price" },
        { text: "Avg Price", value: "avgPrice" },
        { text: "Stop Price", value: "stopPrice" },
        { text: "Executed Qty", value: "executedQty" },
        { text: "Original Qty", value: "origQty" },
        { text: "Update Time", value: "updateTime" },
        { text: "Source", value: "source" },
        { text: "Memo", value: "memo" },
        { text: "Duration", value: "duration" },
      ],
    };
  },
};
</script>

<style lang="scss"></style>
