<template>
  <div>
    <v-data-table dense width="100%" :headers="position_header" :items="positions" class="elevation-1" sort-by="symbol">
      <template v-slot:top>
        <v-row justify="end">
          <v-col v-if="strategyName !== undefined" class="ma-5" cols="1">
            <v-btn @click="tryToAdd">New</v-btn>
          </v-col>
          <v-col class="ma-5" cols="2">
            <v-btn :disabled="positions.length === 0" @click="tryToCoverAll">Close All</v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.symbol="{ item }">
        <b>{{ item.symbol.split("|")[0] }}</b>
        <!-- <v-col :style="`color: ${item.positionSide === 'LONG' ? 'green': 'red'}`">{{ item.symbol.split('|')[1] }}</v-col>-->
      </template>

      <template v-slot:item.positionSide="{ item }">
        <div :style="`color: ${item.positionSide === 'LONG' ? 'green' : 'red'}`">
          {{ item.positionSide }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-col cols="1">
            <v-icon small @click="tryToCover(item)">mdi-delete-empty</v-icon>
          </v-col>
          <v-col cols="1" v-if="is_strategy">
            <v-icon small @click="tryToEdit(item)">mdi-archive-edit-outline</v-icon>
          </v-col>
          <v-col cols="1" v-if="is_strategy">
            <v-icon small @click="tryToOpenTrades(item)">mdi-sigma</v-icon>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <confirm-dialog max_width="400px" text="do u wanna close all positions?" :dialogFlag="dlgCoverAll"
      :cancelFunc="closeCoverAllDlg" :confirmFunc="coverAll"></confirm-dialog>

    <confirm-dialog max_width="400px" text="do u wanna close the position?" :dialogFlag="dlgCover"
      :cancelFunc="closeCoverDlg" :confirmFunc="cover"></confirm-dialog>

    <position-edit-dlg :accountType="accountType" :isNew="isNew" :strategyName="strategyName" :instanceId="instanceId" :gatewayName="gatewayName"
      :editItem="item" :dialogFlag="dlgEdit" :cancelFunc="closeEditDlg"></position-edit-dlg>

    <user-trades-dlg :positionAmt="positionAmt" :entryPrice="entryPrice" :gatewayName="gatewayName"
      :userOrders="userOrders" :dialogFlag="dlgTrades" :cancelFunc="closeTradesDlg"></user-trades-dlg>

  </div>
</template>

<script>
import { tradeMixin } from "@/mixins";

import ConfirmDialog from "@/components/base/ConfirmDialog";
import PositionEditDlg from "@/views/dashboard/component/PositionEditDlg";

import UserTradesDlg from "@/views/dashboard/component/UserTradesDlg";

import { get_all_orders, } from '@/api';

export default {
  name: "PositionTable",

  components: {
    ConfirmDialog,
    PositionEditDlg,
    UserTradesDlg,
  },

  mixins: [tradeMixin],

  props: {
    accountType: {
      type: String,
      default: 'BINANCE_USDT_FUTURE_TEST'
    },
    strategyName: {
      type: String,
    },
    instanceId: {
      type: String,
    },
    gatewayName: {
      type: String,
    },
    is_strategy: {
      type: Boolean,
      default: false,
    },
    positions: Array,
  },

  methods: {

    async tryToOpenTrades(item) {

      this.userOrders = []

      this.positionAmt = item.positionAmt;

      this.entryPrice = item.entryPrice;

      let id = 0

      const all_orders = await get_all_orders({ gatewayName: this.gatewayName, symbol: item.symbol, })
      if (all_orders.all_orders !== undefined) {
        const sortedOrders = all_orders.all_orders
        sortedOrders.sort((a, b) => b.time - a.time)
        this.userOrders = sortedOrders.map((order) => {

          const name = `[${order.symbol}][${order.side}][${order.status}] ---p:${order.price} ---q:${order.origQty} [${new Date(order.time).toLocaleString()}][${order.orderId}]`

          return { id: ++id, rowType: 'Order', name, ...order, children: [] }

        })

      }

      this.dlgTrades = true;
    },

    closeTradesDlg() {
      this.userOrders = [];

      this.positionAmt = 0;

      this.entryPrice = 0;

      this.dlgTrades = false;
    },

    tryToAdd() {
      this.isNew = true;
      this.dlgEdit = true;
    },

    tryToEdit(item) {
      this.isNew = false;
      this.item = item;
      console.log(`tryToEdit: ${this.item.entryPrice} ${this.item.positionAmt}`);
      this.dlgEdit = true;
    },

    closeEditDlg() {
      this.dlgEdit = false;
      this.item = {};
    },

    updateEditItem() {
      this.closeEditDlg();
      console.log('updateEditItem:', this.item);
    },

    deleteEditItem() {
      this.closeEditDlg();
      console.log(this.item);
    },

    tryToCoverAll() {
      this.dlgCoverAll = true;
    },

    closeCoverAllDlg() {
      this.dlgCoverAll = false;
    },

    coverAll() {
      this.closeCoverAllDlg();
      this.positions.forEach((item) => {
        this.coverPosition(item, this.gatewayName);
      })
      // todo
    },

    tryToCover(item) {
      this.item = item;
      this.dlgCover = true;
    },

    closeCoverDlg() {
      this.dlgCover = false;
      this.item = {};
    },

    cover() {
      this.coverPosition(this.item, this.gatewayName);
      this.closeCoverDlg();
    },

  },

  mounted: function () {
    if (this.accountType === 'SPOT') {
      this.position_header.splice(2, 2)
      this.position_header[3].text = 'Cost'
      this.position_header[4].text = 'Market Value'
    }
  },

  data() {
    return {
      positionAmt: 0,
      entryPrice: 0,
      userOrders: [],
      dlgTrades: false,
      item: {},
      isNew: false,
      dlgEdit: false,
      dlgCoverAll: false,
      dlgCover: false,
      position_header: [
        { text: "Symbol", value: "symbol" },
        { text: "Amt", value: "positionAmt" },
        { text: "Side", value: "positionSide" },
        { text: "Leverage", value: "leverage" },
        { text: "Entry Price", value: "entryPrice" },
        { text: "Initial Margin", value: "initialMargin" },
        { text: "Current Margin", value: "currentMargin" },
        { text: "Unrealized Profit", value: "unrealizedPnl" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style lang="scss">
</style>
