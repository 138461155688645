<template>
  <v-dialog persistent v-model="dialogFlag" max-width="400px">
    <v-card>

      <v-card-title>
        <span v-if="isNew" class="text-h5">Add Strategy Position</span>
        <span v-else class="text-h5">Edit Strategy Position</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col v-if="isNew" cols="12" sm="12" md="12">
              <v-text-field v-model="accountType" label="AccountType"></v-text-field>
            </v-col>
            <v-col v-if="isNew" cols="12" sm="12" md="12">
              <v-text-field v-model="item.symbol" label="Symbol"></v-text-field>
            </v-col>
            <v-col v-if="isNew" cols="12" sm="12" md="12">
              <selector-single title="Side" :items="items" :initValue="side" :onChange="onSideChange">
              </selector-single>
            </v-col>
            <v-col v-else cols="12" sm="12" md="12">
              <strong>{{ item.symbol ? editItem.symbol.split("|")[0] : '' }}</strong>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field v-model="item.entryPrice" label="Entry Price"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field v-model="item.positionAmt" label="Amt"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelFunc"> Cancel </v-btn>
        <div v-if="isNew">
          <v-btn color="blue darken-1" text @click="addNewItem"> Save </v-btn>
        </div>
        <div v-else>
          <v-btn color="blue darken-1" text @click="updateEditItem"> Update </v-btn>
          <v-btn color="blue darken-1" text @click="deleteEditItem"> Delete </v-btn>
        </div>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { tradeMixin } from "@/mixins";
import SelectorSingle from '@/views/dashboard/component/SelectorSingle'

import { update_strategy_position, delete_strategy_position, } from "@/api";

export default {
  name: "PositionEditDlg",

  mixins: [tradeMixin],

  components: {
    SelectorSingle,
  },

  props: {
    strategyName: {
      type: String,
    },
    instanceId: {
      type: String,
    },
    accountType: {
      type: String,
    },
    gatewayName: {
      type: String,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    dialogFlag: {
      type: Boolean,
      default: false,
    },
    editItem: {
      type: Object,
      default: {},
    },
    cancelFunc: {
      type: Function,
    },
  },

  methods: {
    onSideChange(ev) {
      this.side = ev;
    },
    addNewItem() {
      this.cancelFunc();

      this.item.strategyName = this.strategyName;
      this.item.instanceId = this.instanceId;
      this.item.gatewayName = this.gatewayName;

      this.item.positionSide = this.side;

      this.item.accountType = this.accountType;

      console.log('addNewItem:', this.item);

      if (this.strategyName === undefined || this.instanceId === undefined) {
        console.warn('no strategyName/instanceId defined!');
        return;
      }

      update_strategy_position(this.item);

    },
    updateEditItem() {
      this.cancelFunc();
      update_strategy_position(this.item);
    },
    deleteEditItem() {
      this.cancelFunc();
      delete_strategy_position(this.item);
    },
  },

  computed: {
    internalDialogFlag: {
      get: function () {
        return this.dialogFlag;
      },
      set: function (newVal) { },
    },
  },

  watch: {
    editItem(val) {
      this.item = val
    },
  },

  data: function () {
    return {
      items: [
        'LONG', 'SHORT', 'BOTH'
      ],
      side: 'LONG',
      item: {},
    };
  },
};
</script>
